import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button/button";

const Page = () => {
  return (
    <LessonLayout
      current_step="5a"
      lesson={"Lesson 2"}
      color={"hs"}
      total_count={10}
      module_title="Separating Fact from Fiction"
      >
      <ContentWrapper>
        <H2>Learn about Clinical Trials</H2>

        <p>When you click the link to the reading, you will see information on the National Institutes of Health (NIH) website. The NIH is the nation’s medical research agency.</p>

        <p>You should focus on the following sections:</p>

        <UnorderedList>
          <li>What are clinical trials and why do people participate?</li>
          <li>What are clinical trials and why would I want to take part?</li>
          <li>How does the research process work?</li>
          <li>What are clinical trial protocols?</li>
          <li>What is an Institutional Review Board?</li>
          <li>What is informed consent?</li>
          <li>What are the phases of clinical trials?</li>
          <li>What do the terms placebo, randomization, and blinded mean in clinical trials?</li>
          <li>Who takes part in clinical trials?</li>
          <li>What do I need to know if I am thinking about taking part in a clinical trial?</li>
          <li>How is my safety protected?</li>
          <li>What happens after a clinical trial is completed?</li>
        </UnorderedList>

        <p>Click on the following link to learn about what clinical studies are and why they are done.</p>

        <Button
          action="secondary"
          className="m-2"
          color="indigo"
          href="https://www.nih.gov/health-information/nih-clinical-research-trials-you/basics"
          icon="external"
          title="Reading 1"
        >
          Reading 1
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;
